import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ApartmentIcon from '@material-ui/icons/Apartment';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

export default function Features(props) {
  const content = {
    'badge': 'WHY US?',
    'header': '15 years of experience in PAN services',
    'description': 'We respet your privacy, information shared to us is as secured as your money in your own vault, we care for each application.',    
    'col1-desc': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'col2-desc': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'col3-desc': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'col4-desc': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ...props.content
  };

  return (
    <section>
      <Container maxWidth="lg">
        <Box py={6}>
          <Box textAlign="center" mb={8}>
            <Container maxWidth="sm">
              <Typography variant="overline" color="textSecondary">{content['badge']}</Typography>
              <Typography variant="h3" component="h2" gutterBottom={true}>{content['header']}</Typography>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true}>{content['description']}</Typography>
            </Container>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Box mb={2}>
                  <ApartmentIcon color="primary" fontSize="large" />
                </Box>
                <Typography variant="subtitle1" component="p" color="textSecondary">{content['col1-desc']}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Box mb={2}>
                  <DevicesOtherIcon color="primary" fontSize="large" />
                </Box>
                <Typography variant="subtitle1" component="p" color="textSecondary">{content['col2-desc']}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Box mb={2}>
                  <FolderSharedIcon color="primary" fontSize="large" />
                </Box>
                <Typography variant="subtitle1" component="p" color="textSecondary">{content['col3-desc']}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Box mb={2}>
                  <BusinessCenterIcon color="primary" fontSize="large" />
                </Box>
                <Typography variant="subtitle1" component="p" color="textSecondary">{content['col4-desc']}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}