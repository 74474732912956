import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  number: {
    backgroundColor: theme.palette.background.emphasis,
    color: theme.palette.text.secondary
  },
  img: {
    maxWidth: 256,
    marginBottom: theme.spacing(2),
    height: 300
  },
  stepActive: {
    border: '1px solid',
    borderColor: theme.palette.background.secondary,
    borderRadius: theme.shape.borderRadius
  }
}));

export default function HowItWorks(props) {
  const classes = useStyles();
  
  const content = {
    'badge': 'THIS IS HOW WE WORK',
    'header-p1': 'Follow 4 Simple steps',
    'header-p2': 'your PAN card will reach at right time.',
    'description': 'Integer feugiat massa sapien, vitae tristique metus suscipit nec.',
    'col1-image': 'nereus-assets/img/appflow/applynow.svg',
    'col1-header': 'Select Forms',
    'col1-desc': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'col2-image': 'nereus-assets/img/appflow/fillingforms.svg',
    'col2-header': 'Filling Forms',
    'col2-desc': 'Donec id lorem eget purus maximus suscipit nec vitae quam.',
    'col3-image': 'nereus-assets/img/appflow/payment.svg',
    'col3-header': 'Check Out',
    'col3-desc': 'Vivamus fringilla finibus tincidunt. Integer mollis pellentesque libero.',
    'col4-image': 'nereus-assets/img/appflow/download.svg',
    'col4-header': 'Downloads',
    'col4-desc': 'Vivamus fringilla finibus tincidunt. Integer mollis pellentesque libero.',
    'col5-image': 'nereus-assets/img/appflow/mail.svg',
    'col5-header': 'Mail Us',
    'col5-desc': 'Vivamus fringilla finibus tincidunt. Integer mollis pellentesque libero.',
    ...props.content
  };

  return (
    <section>
      <Container maxWidth="lg">
        <Box py={10} textAlign="center">
          <Box mb={8}>
            <Container maxWidth="sm">
            <Typography variant="overline" color="textSecondary" paragraph={true}>{content['badge']}</Typography>
              <Typography variant="h3" component="h2" gutterBottom={true}>
                <Typography variant="h3" component="span" color="primary">{content['header-p1']} </Typography>
                <Typography variant="h3" component="span">{content['header-p2']}</Typography>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true}>{content['description']}</Typography>
            </Container>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <Box p={3} pb={4}>
                <Box display="flex" justifyContent="center" mt={1} mb={4}>
                  <Avatar className={classes.number}>1</Avatar>
                </Box>
                <img src={content['col1-image']} alt="" className={classes.img} />
                <Typography variant="h6" component="h3" gutterBottom={true}>{content['col1-header']}</Typography>
                <Typography variant="body2" component="p" color="textSecondary">{content['col1-desc']}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={3} pb={4}>
                <Box display="flex" justifyContent="center" mt={1} mb={4}>
                  <Avatar className={classes.number}>2</Avatar>
                </Box>
                <img src={content['col2-image']} alt="" className={classes.img} />
                <Typography variant="h6" component="h3" gutterBottom={true}>{content['col2-header']}</Typography>
                <Typography variant="body2" component="p" color="textSecondary">{content['col2-desc']}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={3} pb={4}>
                <Box display="flex" justifyContent="center" mt={1} mb={4}>
                  <Avatar className={classes.number}>3</Avatar>
                </Box>
                <img src={content['col3-image']} alt="" className={classes.img} />
                <Typography variant="h6" component="h3" gutterBottom={true}>{content['col3-header']}</Typography>
                <Typography variant="body2" component="p" color="textSecondary">{content['col3-desc']}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={3} pb={4}>
                <Box display="flex" justifyContent="center" mt={1} mb={4}>
                  <Avatar className={classes.number}>4</Avatar>
                </Box>
                <img src={content['col4-image']} alt="" className={classes.img} />
                <Typography variant="h6" component="h3" gutterBottom={true}>{content['col4-header']}</Typography>
                <Typography variant="body2" component="p" color="textSecondary">{content['col4-desc']}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={3} pb={4}>
                <Box display="flex" justifyContent="center" mt={1} mb={4}>
                  <Avatar className={classes.number}>5</Avatar>
                </Box>
                <img src={content['col5-image']} alt="" className={classes.img} />
                <Typography variant="h6" component="h3" gutterBottom={true}>{content['col5-header']}</Typography>
                <Typography variant="body2" component="p" color="textSecondary">{content['col5-desc']}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}