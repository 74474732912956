import React from 'react';

import HorizontalNav2 from '../components/horizontal-navs/HorizontalNav2';
import CallToAction2 from '../components/call-to-action/CallToAction2';
import Contact1 from '../components/contacts/Contact1';
import Footer3 from '../components/footers/Footer3';

export default function Contact() {
  return (
    <React.Fragment>
      <HorizontalNav2 content={null} />

      <CallToAction2 content={null} />

      <Contact1 content={null} />

      <Footer3 content={null} />
    </React.Fragment>
  );
}

