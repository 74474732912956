import React from 'react';

import HorizontalNav2 from '../components/horizontal-navs/HorizontalNav2';
import CallToAction2 from '../components/call-to-action/CallToAction2';
import Header1 from '../components/headers/Header1';
import HowItWorks4 from '../components/how-it-works/HowItWorks4';
import Features5 from '../components/features/Features5';
import Contact1 from '../components/contacts/Contact1';
import Footer3 from '../components/footers/Footer3';

export default function Index() {
  return (
    <React.Fragment>
      <HorizontalNav2 content={null} />

      <Header1 content={null} />

      <HowItWorks4 content={null} />

      <Features5 content={null} />

      <Contact1 content={null} />

      <Footer3 content={null} />
    </React.Fragment>
  );
}

